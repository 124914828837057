import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import { roles } from '../../utility/Constant';
import SearchIcon from '@material-ui/icons/Search';
import { toast } from 'react-toastify';
import FormBuilderService from '../../services/form-builder.service';

const styles = () => ({
    MuiPaper: {
        width: '100%',
    },
    searchBar: {
        width: '70%',
    },
    errorText: {
        color: '#f44336'
    },
})

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: roles,
            users: props.users,
            searchText: '',
            showAddUserForm: false,
            showNoUserMessage: false,
            showAddUserErrorMessage : false,
            hasError: false,
            formValues: {
                sso: '',
                firstName: '',
                lastName: '',
                email: '',
                role: ''
            }
        }
        this.formBuilderService = new FormBuilderService();
    }

    componentDidUpdate(prevProps){
        if (prevProps.users !== this.props.users) {
            this.setState({
                users: this.props.users
            });
        }
    }

    changeHandler = (event) => {
        if (event) {
            const name = event.target.name;
            const value = event.target.value;
            this.updateFormValue(name, value);
        }
    }

    updateFormValue = (name, value) => {
        this.setState({
            formValues: { ...this.state.formValues, [name]: value }
        })
    }

    formSubmitHandler = () => {
        const isValid = this.validatePopup();
        if (isValid) {
            var payload = this.state.formValues;
            this.formBuilderService.addNewUser(payload).then((res) => {
                if (res.data) {
                    toast.success("New User added successfuly!");
                    this.formBuilderService.getUserDetails().then(response => {
                        if (response.data) {
                            this.props.getUserDetailsFromAddUser(response.data)
                        }
                    });
                } else {
                    toast.error("User could not be added due to errors!")
                }
            });
            this.props.onClose();
        } else {
            this.setState({
                hasError: true
            });
        }
    }

    validatePopup = () => {
        var valid = false;
        if (this.state.formValues.role && this.state.formValues.role.length) {
          valid = true;
        }
        return valid;
      }

    startTimerForSearch = (value) => {
        if (value) {
            this.setState({
                searchText: value
            });
        } else {
            this.setState({
                searchText: '',
                showAddUserForm: false,
                showNoUserMessage: false,
                showAddUserErrorMessage : false
            });
        }
    }

    handleExpandClick = () => {
        let userValue = this.state.searchText;
        this.formBuilderService.getUserSSODetails(userValue).then(response => {
            if (response.data.sso || response.data.email) {
                let userData = this.state.users.filter((user) => {
                    if (user.sso === response.data.sso) {
                        return user;
                    }
                    else if (user.email === response.data.email) {
                        return user;
                    }
                    else {
                        return '';
                    }
                });
                if (userData.length === 0) {
                    this.setState({
                        formValues: {
                            ...this.state.formValues,
                            sso: response.data.sso,
                            firstName: response.data.firstName,
                            lastName: response.data.lastName,
                            email: response.data.email,
                            role: response.data.role ? response.data.role : ''
                        },
                        showAddUserForm: true,
                        showNoUserMessage: false,
                        showAddUserErrorMessage : false
                    });
                } else {
                    this.setState({
                        showAddUserForm: false,
                        showNoUserMessage: false,
                        showAddUserErrorMessage : true
                    });
                }
            }
            else{
                this.setState({
                    showAddUserForm: false,
                    showNoUserMessage: true,
                    showAddUserErrorMessage : false
                });
            }
        });
    }

    getHelperTextForRequiredField = (value) => {
        let helperText = '';
        if (this.state.hasError) {
          if (value.length === 0) {
            helperText = 'Role is required!';
          } else {
            return;
          }
        }
        return helperText;
      }

    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose} classes={{ paper: classes.MuiPaper }}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <Typography component="div" className={classes.root} >
                        <Card>
                            <Box p={3}>
                                <Grid container>
                                    <Grid item md={12} xs={12}>
                                        <Typography component="div">
                                            <TextField variant="outlined"
                                                className={classes.searchBar}
                                                id="search"
                                                name="search"
                                                label="Search User by Email, SSO"
                                                autoComplete='off'
                                                value={this.state.searchText}
                                                onChange={event => {
                                                    this.startTimerForSearch(event.target.value)
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton edge="start"
                                                                onClick={this.handleExpandClick}>
                                                                <SearchIcon id="searchIcon" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}>
                                            </TextField>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                        <br></br>
                        {this.state.showAddUserForm ? <Fragment><Card>
                            <form autoComplete="off" noValidate>
                                <Box p={3}>
                                    <Grid container spacing={3} >
                                        <Grid item md={6} xs={6} >
                                            <TextField fullWidth id="sso"
                                                label="SSO" name="sso"
                                                value={this.state.formValues.sso}
                                                required variant="outlined"
                                                inputProps={
                                                    { readOnly: true, }
                                                }/>
                                        </Grid>
                                        <Grid item md={6} xs={6} >
                                            <TextField fullWidth id="email"
                                                label="Email" name="email"
                                                value={this.state.formValues.email}
                                                required variant="outlined" 
                                                inputProps={
                                                    { readOnly: true, }
                                                }/>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider light />

                                <Box p={3}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={6}>
                                            <TextField fullWidth id="firstName"
                                                label="First Name" name="firstName"
                                                value={this.state.formValues.firstName}
                                                required variant="outlined" 
                                                inputProps={
                                                    { readOnly: true, }
                                                }/>
                                        </Grid>

                                        <Grid item md={6} xs={6}>
                                            <TextField fullWidth id="lastName"
                                                label="Last Name" name="lastName"
                                                value={this.state.formValues.lastName}
                                                required variant="outlined" 
                                                inputProps={
                                                    { readOnly: true, }
                                                }/>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider light />
                                <Box p={3}>
                                    <Grid container spacing={3} >
                                        <Grid item md={12} xs={12} >
                                            <TextField select fullWidth
                                                label="Role" name="role" required variant="outlined"
                                                value={this.state.formValues.role}
                                                error={this.state.hasError && !this.state.formValues.role.length}
                                                onChange={this.changeHandler}>
                                                {(this.state.roles.length > 0) ?
                                                    this.state.roles.map((role) => (
                                                        <MenuItem key={role.roleId} value={role.roleId}>
                                                            {role.roleName}
                                                        </MenuItem>)) :
                                                    <MenuItem key='none' value='none'>None</MenuItem>}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Card>
                        <FormHelperText className={classes.errorText}>{this.getHelperTextForRequiredField(this.state.formValues.role)}</FormHelperText></Fragment> : null}
                        {this.state.showNoUserMessage ?
                            <Typography className={classes.errorText}>No Users found!</Typography> : null}
                        {this.state.showAddUserErrorMessage ?
                            <Typography className={classes.errorText}>User Already Added!</Typography> : null}
                        <br />
                    </Typography>
                </DialogContent>
                {this.state.showAddUserForm ? <DialogActions>
                    <Button onClick={this.props.onClose} id="cancel" color="secondary" variant="outlined">Cancel</Button>
                    <Button onClick={this.formSubmitHandler} variant="contained" id="save">Save</Button>
                </DialogActions> : null }
            </Dialog>)
    }
}

export default withStyles(styles)(AddUser);