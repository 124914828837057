import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { FormHelperService } from "../services/form.helper.service";
import { EMPTY_STRING, OTHERS } from "../utility/Constant";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { ReactComponent as ConditionalFormArrow } from "../shared/imgs/conditionalFormArrow.svg";

export const ZERO_LEVEL = 0;
export const FIRST_LEVEL = 1;
export const MAX_LEVEL = 4;

const UniversalList = ({
  universalListData,
  setUniversalListData,
  universalListOptions,
  createFormClicked,
  onValidation,
}) => {
  const useStyles = makeStyles((theme) => ({
    addIcon: {
      color: "#419BAC",
      fontFamily: "Roboto",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      padding: "0",
      height: "fit-content",
    },
    iconButton: {
      padding: "0",
      height: "fit-content",
      marginTop: "16px"
    },
    universalListBox: {
      margin: theme.spacing(2),
      padding: "14px 16px",
      border: "1px solid",
      borderColor: "#E0E0E0",
      borderRadius: "8px",
      position: "relative",
    },
    backgroundEven: {
      backgroundColor: "white",
    },
    backgroundOdd: {
      backgroundColor: "#F8F8F8",
    },
    universalFormContainer: {
      display: "flex",
    },
    universalTypeLabel: {
      paddingRight: "24px",
      fontSize: "16px",
      color: "#707070",
      height: "fit-content",
      marginTop: "16px"
    },
    accordionDetailsSection: {
      display: "block",
    },
    lastLevelBox: {
      marginTop: "8px",
    },
    collapseOpen: {
      maxHeight: "fit-content",
    },
    collapseArrow: {
      position: "absolute",
      right: "20px",
      top: "21px",
      cursor: "pointer",
    },
    arrowExpanded: {
      transition: "0.3s ease-out",
    },
    arrowCollapsed: {
      transform: "rotate(180deg)",
      transition: "0.3s ease-out",
    },
    formControlContainer: {
      width: "50%",
      marginRight: "8px"
    },
  }));

  const classes = useStyles();
  const [parentCollapseList, setParentCollapseList] = useState({});
  const universalListDataValidation = useCallback((options) => {
    return options?.every((option) => {
      if (!option?.name) {
        return false;
      }
      if (option?.options) {
        return universalListDataValidation(option?.options);
      }
      return true;
    });
  }, []);

  useEffect(() => {
    const isValid = universalListDataValidation(universalListData?.options);
    onValidation(isValid && universalListData?.name?.length);
  }, [
    createFormClicked,
    onValidation,
    universalListData,
    universalListDataValidation,
  ]);

  const updateFieldsInUnveralList = (options, id, value) => {
    return options?.map((item) => {
      if (item?.id === id) {
        return {
          ...item,
          name: value,
        };
      } else {
        return {
          ...item,
          options: updateFieldsInUnveralList(item?.options, id, value),
        };
      }
    });
  };

  const handleChange = (event, id) => {
    event.stopPropagation();
    setUniversalListData((prevState) => ({
      ...prevState,
      options: updateFieldsInUnveralList(
        prevState?.options,
        id,
        event.target.value
      ),
    }));
  };

  const frameNestedItems = (optionCopy, parentId) => {
    return optionCopy?.map((option) => {
      const elementId = FormHelperService.generateElementId();
      return {
        ...option,
        id: elementId,
        name: EMPTY_STRING,
        parentId: parentId,
        options: frameNestedItems(option?.options, elementId),
      };
    });
  };

  const frameItemsToAdd = (options, index) => {
    const lastOption = options[index];
    const optionCopy = [JSON.parse(JSON.stringify(lastOption))];
    return [
      ...options,
      ...frameNestedItems(optionCopy, optionCopy[0]?.parentId),
    ];
  };

  const addItemsInUniversalList = (options, id) => {
    return options?.map((item) => {
      if (item?.options?.some((item) => item?.id === id)) {
        const index = item?.options?.findIndex((item) => item?.id === id);
        return {
          ...item,
          options: frameItemsToAdd(item?.options, index),
        };
      } else {
        return {
          ...item,
          options: addItemsInUniversalList(item?.options, id),
        };
      }
    });
  };

  const handleAddUniversalList = (id, level) => {
    if (level !== FIRST_LEVEL) {
      setUniversalListData((prevState) => ({
        ...prevState,
        options: addItemsInUniversalList(prevState?.options, id, level),
      }));
    } else {
      setUniversalListData((prevState) => {
        const index = prevState?.options?.findIndex((item) => item?.id === id);
        return {
          ...prevState,
          options: frameItemsToAdd(prevState?.options, index),
        };
      });
    }
  };

  const removeItemsFromUniversalList = (options, id) => {
    return options
      ?.map((item) => {
        if (item.id === id) {
          return null;
        }
        if (item.options) {
          return {
            ...item,
            options: removeItemsFromUniversalList(item.options, id),
          };
        }
        return item;
      })
      .filter((item) => item !== null);
  };

  const handleRemoveUniversalList = (id, itemLevel) => {
    if (itemLevel === FIRST_LEVEL && parentCollapseList[id] !== undefined) {
      // Whenever a delete operation occurs, the corresponding IDs should be removed from the collapse state.
      setParentCollapseList((prevState) => {
        const collpaseItemList = { ...prevState };
        delete collpaseItemList[id];
        return collpaseItemList;
      });
    }
    setUniversalListData((prevState) => ({
      ...prevState,
      options: removeItemsFromUniversalList(prevState?.options, id),
    }));
  };

  const handleCollapse = (id) => {
    setParentCollapseList((prevState) => ({
      ...prevState,
      [id]: prevState[id] !== undefined ? !prevState[id] : false,
    }));
  };

  const CommonUniversalListForm = (options, item) => {
    return (
      <>
        <span className={classes.universalTypeLabel}>
          {item?.universalType}
        </span>
        <FormControl className={classes.formControlContainer}>
          {item?.universalType !== OTHERS ? (
            <TextField
              select
              fullWidth
              variant="outlined"
              value={item?.name}
              defaultValue={EMPTY_STRING}
              onChange={(e) => handleChange(e, item?.id)}
              error={createFormClicked && !item?.name?.length}
              helperText={
                createFormClicked && !item?.name?.length
                  ? "Please select a type"
                  : EMPTY_STRING
              }
            >
              {universalListOptions[item?.level]?.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  disabled={options?.some((item) => item?.name === option)}
                >
                  {option}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              fullWidth
              variant="outlined"
              placeholder={OTHERS}
              value={item?.name}
              onChange={(e) => handleChange(e, item?.id)}
              error={createFormClicked && !item?.name?.length}
              helperText={
                createFormClicked && !item?.name?.length
                  ? "Please enter a value"
                  : EMPTY_STRING
              }
            ></TextField>
          )}
        </FormControl>
        {options?.length !== 1 && (
          <IconButton
            className={classes.iconButton}
            onClick={() => handleRemoveUniversalList(item?.id, item?.level)}
          >
            <DeleteIcon className={classes.deleteIcon} />
          </IconButton>
        )}
        <IconButton
          className={classes.iconButton}
          onClick={() => handleAddUniversalList(item?.id, item?.level)}
        >
          <AddIcon className={classes.addIcon} />
        </IconButton>
        {item.level === FIRST_LEVEL && universalListData?.options[0]?.options[0]?.universalType !== "" && universalListData.totalLevels > 1 && (
          <ConditionalFormArrow
            className={`${classes.collapseArrow} ${item.level === FIRST_LEVEL && parentCollapseList[item.id] === false
              ? classes.arrowCollapsed
              : classes.arrowExpanded
              }`}
            onClick={() => handleCollapse(item.id)}
          />
        )}
      </>
    );
  };

  const renderLastLevel = (options) => {
    const lastLevel = options?.length && options?.[0]?.level === MAX_LEVEL && options?.[0]?.universalType !== "";
    if (lastLevel) {
      return (
        <Box
          key={"lastLevel"}
          className={`${classes.universalListBox} ${classes.backgroundEven}`}
        >
          {options?.map((item, index) => {
            if (!item?.universalTypeId) return null;
            return (
              <div
                className={`${classes.universalFormContainer} ${index !== 0 && classes.lastLevelBox
                  }`}
              >
                {CommonUniversalListForm(options, item)}
              </div>
            );
          })}
        </Box>
      );
    }
  };

  const renderLevel = (options) => {
    return options?.map(
      (item) =>
        item?.universalTypeId &&
        (item.level === FIRST_LEVEL ? (
          levelContainerBox(options, item)
        ) : (
          <Collapse in={parentCollapseList[item.parentId] !== undefined ? parentCollapseList[item.parentId] : true}>
            {levelContainerBox(options, item)}
          </Collapse>
        ))
    );
  };

  const levelContainerBox = (options, item) => (
    <Box
      key={item.id}
      className={`${classes.universalListBox} ${item.level % 2 === 0
        ? classes.backgroundEven
        : classes.backgroundOdd
        }`}
    >
      <div className={classes.universalFormContainer}>
        {CommonUniversalListForm(options, item)}
      </div>
      {item.level === MAX_LEVEL - 1
        ? renderLastLevel(item?.options)
        : renderLevel(item?.options)}
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" p={2}>
      {renderLevel(universalListData?.options)}
    </Box>
  );
};

export default UniversalList;
