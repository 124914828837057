const awsconfig = {
  Auth: {
    Cognito: {
      userPoolId: 'us-east-1_vJhnDGSVK',
      userPoolClientId: process.env.REACT_APP_CLIENT_ID,
      region: "us-east-1",
      loginWith: {
        oauth: {
          domain: "fw2-next-gen-authcode-flow.auth.us-east-1.amazoncognito.com",
          scopes: ["openid", "email", "phone", "fwapi/fw_admin_access"],
          redirectSignIn: [process.env.REACT_APP_REDIRECT_URL],
          redirectSignOut: [process.env.REACT_APP_REDIRECT_URL],
          responseType: "code",
        },
      },
    },
  },
};

export default awsconfig;
