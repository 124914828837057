import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Dialog } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    deleteButton:{
        border: '1px solid #FE1919', 
        color:"white",
        backgroundColor:"#FE1919",
        '&:hover': { 
          backgroundColor: '#FE1919' 
        } 
    
       },
    uppercase: {
        textTransform: 'uppercase'
    },
    title: {
        paddingBottom: "5px",
    },
    dialogPaper: {
        width: (props) => props.dialogWidth || 'auto', // Dynamically use the dialogWidth prop or default to auto
        maxWidth: 'none', // Removes the default maxWidth constraint
      },
}));


export default function ConfirmationDialog(props) {
    const { onClose, open, onDelete, description, title, type, onApprove,children,buttonTitle,mainButtonDisabled=false,dialogWidth,transferButton=false } = props;
    const classes = useStyles({ dialogWidth });

    const handleCancel = () => {
        onClose();
    };

    const handleDelete = () => {
        onDelete();
    };
    
    const handleApprove = () => {
        onApprove();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle className={`${classes.uppercase} ${classes.title}`} disableTypography >{title}</DialogTitle>
                <DialogContent>
                    {
                        description&&
                        <DialogContentText id="alert-dialog-description">
                            {description}
                        </DialogContentText>
                    }
                    {
                        children&&children
                    }
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={handleCancel} color="inherit" variant='outlined'>
                        Cancel
                    </Button>
                    {(type === 'delete') ? <Button onClick={handleDelete} className={classes.deleteButton} variant="outlined" color="#FE1919">
                        Delete
                    </Button> :
                        <Button  onClick={handleApprove} variant="contained" color={transferButton?"default":""} disabled={mainButtonDisabled}>
                            {buttonTitle||'Approve'}
                        </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
