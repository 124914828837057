import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports.js";


try {
  Amplify.configure(awsconfig);
} catch (error) {
  console.error("Error while configuring Amplify:", error);
}

const initializeApp = () => {

    return (
      <Router>
        <App />
      </Router>
    )
}

ReactDOM.render(initializeApp(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
