import './App.scss';
import '../shared/style/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../shared/theme/Theme';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from '../route/Routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { UserInfoService } from '../services/user-info.service';
import FormBuilderService from '../services/form-builder.service';
import { AccessToken } from "../utility/Constant";
import { signInWithRedirect, fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';

const browserHistory = createBrowserHistory();

class App extends Component {
  constructor() {
    super();
    this.userInfoService = new UserInfoService();
    this.formBuilderService = new FormBuilderService();
    this.state = { userSSO: null, userRole: '', user: {}, loading: true }
  }

  async silentAuth() {
    try {
      // Check if user is already logged in
      const user = await getCurrentUser();

      // Fetch the current session
      const session = await fetchAuthSession();

      // Extract tokens from session
      const accessToken = session.tokens?.accessToken?.toString();

      if (!accessToken) {
        await signInWithRedirect();
        return;
      }

      // Store tokens separately in localStorage
      localStorage.setItem('access_token', accessToken);
      this.setState({
        loading: false,
      });
      let localRole = localStorage.getItem('user_role');
      let userRole = (localRole && localRole.length > 0) ?
      AccessToken.scope[localRole] : AccessToken.scope.TECHNICIAN;
      let decodedSSO = user.username.split("_")[1];
      localStorage.setItem('ge_sso', decodedSSO);
      this.userInfoService.getUserInfo(decodedSSO).then((response) => {
        const sso = response;
          this.setState({
            userSSO: sso/* awaiting to get sso value from getUserInfo else we will get null value and we cannot able to view the application.*/,
          }, () => {
            if(response) {
              this.setState({
                user: response,
                userRole: response.userRole,
              });
            }
          });
        });
      } catch (error) {
        console.error("Authentication failed:", error.message);
        await signInWithRedirect(); // Redirect to login if session fails
    }
  }
 
  async componentDidMount() {
    this.silentAuth();
  }

  render() {
    const { userSSO, loading } = this.state;

    return (
      <Fragment>
        {userSSO && !loading && 
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={browserHistory}>
                <Routes userRole={this.state.userRole} />
              </Router>
            </MuiPickersUtilsProvider>
            <ToastContainer hideProgressBar={true} position='bottom-center' 
            	draggable={false} />
          </ThemeProvider>
        }  
      </Fragment>
    );
  }
}

export default App;