export const KeywordConstant = {
  MIN_KEYWORD: 1, MIN_CHAR_IN_KEYWORD: 3, DELAY_IN_KEYWORD_SUGGEST_API_CALL: 5
};
export const barCodeTypes = ["GE ID Tag", "BOL Tag", "Serial NO #"];
export const QuestionDeleteMsg = 'Are you sure you want to delete this question?';
export const SectionDeleteMsg = 'Are you sure you want to delete this section?';
export const SubsectionDeleteMsg = 'Are you sure you want to delete this subsection?';
export const debugState = false;

export const roles = [
  { roleId : "GLOBALADMIN", roleName : 'Global Admin'},
  { roleId : "SITEMANAGER", roleName : 'Site Manager'},
  { roleId : "TECHNICIAN", roleName : 'Technician'},
  { roleId : "ASSETMANAGER", roleName : 'Asset Manager'},
];
export const userObject = {
  sub: "223031958", firstname: "Ramanathan", lastname: "Murugesan ",
  mail: "Farija.Parveen@ge.com", userRole: "GLOBALADMIN",
  title: "DT Services - Author", employeetype: "Contractor",
  gehrbusinesssegment: "Renewable Energy COEs",
  gessobusinessunit: "REN COES-Digital Technology",
  gessouid: "21E31968-34D2-1F06-B460-002128B20D70"
};
export const AccessToken = {
  role: {
	ASSETMANAGER: 'ASSETMANAGER', GLOBALADMIN: 'GLOBALADMIN',
	SITEMANAGER: 'SITEMANAGER', TECHNICIAN: 'TECHNICIAN'
  },
  scope: {
	ASSETMANAGER: 'fwapi/fw_write_access', GLOBALADMIN: 'fwapi/fw_admin_access', 
	SITEMANAGER: 'fwapi/fw_write_access', TECHNICIAN: 'fwapi/fw_read_access'
  }
};
export const messages = {
  CANNOT_UPLOAD_MORE_IMAGES: 'User can upload only 3 images.!',
  IMAGE_UPLOADED_MESSAGE: 'Image uploaded successfully.!',
  IMAGE_DELETED_MESSAGE: 'Image deleted successfully.!',
  ERROR_MESSAGE: 'Could not able to perform action due to ',
  LOREM_IPSUM: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' + 
    'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
};
export const GeneralConfig = { 
  MAX_IMAGE_COUNT: 3, DELETE: 'DELETE', GET: 'GET', UPLOAD: 'UPLOAD'
};
export const SUB_SECTION = 'subsection';
export const SECTION = 'section';
export const YES = 'YES';
export const NO = 'NO';
export const SELECT_TYPE = 'Select Type';
export const REVIEW_PAGE_MAX_PDF_SIZE = 5;
export const OTHERS = 'Others';
export const EMPTY_STRING = '';
