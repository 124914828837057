import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import Titlebar from '../shared/component/Titlebar'
import { makeStyles, Card, Box, Typography, Button, IconButton, MenuItem, Grid, 
	TextField } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FormEdit from '../components/form-element/FormEdit';
import { Loader } from '../components/Loader';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { FormStore, formState, loaderState, FORM_STATUS, dialogState,
  sideTocState, publishedFormState, } from '../services/form.store';
import EditFormMetaData from './EditFormMetaData';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import TuneSharpIcon from '@material-ui/icons/TuneSharp';
import TranslateIcon from '@material-ui/icons/Translate';
import FormBuilderService from '../services/form-builder.service';
import { toast } from 'react-toastify';
import { htmlBase64Utils } from '../utility/CommonUtils';

const styles = theme => ({
  root: {
    padding: '12px',
    paddingBottom: '80px'
  },
  flexGrow: {
    flexGrow: 1
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  toolBar: {
    backgroundColor: '#242D3E',
    bottom: 0,
    top: 'auto',
    position: 'relative',
    width: '100%',
    padding: 20
  },
  draftButton: {
    border: '1px solid white',
    color: 'white'
  },
  previewButton: {
    color: 'white'
  }
});

const textFieldStyles = makeStyles(theme => ({
  textField: {
    "& .MuiInputLabel-shrink": {
		marginTop: 10,
	    marginLeft: -20
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    paddingLeft: 0,
    fontWeight: 500,
    marginTop: -10
  }
}));

class EditForm extends Component {

  constructor() {
    super();
    this.state = {
      form: {},
      translatedForm: {},
      loading: true,
      showDialog: false,
      showTOC: false,
      showSlide: false,
      languages: [], selectedLanguage: '', showPopup: false, defaultTabName: '',
      enableSaveBtn: true
    };
    this.formBuilderService = new FormBuilderService();
  }

  componentDidMount() {
    const formId = this.props.match.params.id;
    this.formSubscription = formState.subscribe(response => {
      htmlBase64Utils(response, false);
      this.setState({ form: response });
      if (response.defaultLanguageCode !== undefined && 
    	response.defaultLanguageCode !== null) {
        this.setState({
          form: {
            ...this.state.form, 
            'translationFromCode': response.defaultLanguageCode
          }
        });
      } else {
        this.setState({
          form: { ...this.state.form, 'translationFromCode': 'en' }
        });
      }
    });
    this.loaderSubscription = loaderState.subscribe(isLoading => {
      this.setState({ loading: isLoading });
    });
    this.publishedFormSubscription = publishedFormState.subscribe(
      isLastUpdatedVersion => {
      this.setState({ enableSaveBtn: isLastUpdatedVersion });
    });
    this.dialogSubscription = dialogState.subscribe(value => {
      this.setState({ showDialog: value });
    })
    this.sideTocState = sideTocState.subscribe(value => {
      if (value) {
        this.setState({
          showSlide: !value,
          showTOC: value
        });
      }
    })
    FormStore.sharedInstance().load(formId);
    this.formBuilderService.getDefaultLanguages().then(response => {
      this.setState({
        languages: response.data
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    let currentId = this.state.form.id;
    if (prevState.form.id && prevState.form.id !== currentId) {
      this.props.history.push('/editForm/' + currentId);
    }
  };

  componentWillUnmount() {
    this.formSubscription.unsubscribe();
    this.loaderSubscription.unsubscribe();
    this.dialogSubscription.unsubscribe();
    this.sideTocState.unsubscribe();
  };
  
  saveAsDraft = () => {
    this.handleSave(FORM_STATUS.DRAFT);
  };

  publishForm = () => {
    this.handleSave(FORM_STATUS.PUBLISHED);
  };

  handleSave = (status) => {
    FormStore.sharedInstance().updateFormStatus(status);
    let errorIds = FormStore.sharedInstance().getErrorIds();
    if (errorIds.length !== 0) {
      toast.error("Form could not be saved due to errors in your form.");
    }
    else{
      FormStore.sharedInstance().saveForm().then((res) => {
        if (status === FORM_STATUS.PUBLISHED && !res.error) {
          this.props.history.push('/existingForms');  /* Removed setTImeout function that reduced the performance to 2 seconds */
        }
      });
    }
  };

  isDisabled = () => {
    return this.state.form.formStatus === FORM_STATUS.PUBLISHED;
  };

  updateFormMetaData = () => {
    this.setState({ showDialog: true });
  };

  handleCancel = () => {
    this.setState({ showDialog: false });
  };

  handleEditView = (open) => (event) => {
    this.setState({
      showTOC: true,
      showSlide: false
    });
  };

  toggleDrawer = (open) => (event) => {
    this.setState({
      showSlide: open,
      showTOC: !open
    });
  };

  languageHandler = (event, key) => {
    const transationCodeModify = key.key?.includes('.$') ? key.key.replace(/^.\$/, '') : key.key
    if (event) {
      this.setState({
        form: {
          ...this.state.form, 'translationToCode': transationCodeModify,
        },
        selectedLanguage: event.target.value,
        translationCode: transationCodeModify
      }, () => {
        if (this.state.form.translationFromCode && this.state.form.translationToCode) {
          this.formBuilderService.getTranslatedForm(this.state.form).then((res) => {
            if (res.data) {
              this.setState({
                translatedForm: res.data,
                defaultTabName: this.state.languages.filter(
                		language => this.state.form.translationFromCode.includes(language.languageCode)).map(language => language.name),
                showPopup: true
              });
              FormStore.sharedInstance().sendTranslateForm(res.data, 
            		  this.state.form.translationToCode);
            } else {
              console.log('data', res.data);
            }
          });
        }
      });
    }
  };

  closeDrawer = () => {
    this.setState({ showSlide: false });
  }

  handleClose = () => {
    this.setState({ showPopup: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <FormTitle title={this.state.form.title} 
          updateFormMetaData={this.updateFormMetaData}
          slideInOut={this.toggleDrawer} showToc={this.state.showTOC}
          handleEditView={this.handleEditView}
          showLanguages={this.languageHandler} languages={this.state.languages}
          form={this.state.form} translateIcon={<TranslateIcon />}
          className={classes} isLoading={this.state.loading}/>
        <Typography component="div" className={classes.root}>
          <Card square>
            <RenderForm showPopup={this.state.showPopup} 
            	selectedLanguage={this.state.selectedLanguage} 
            	defaultTabName={this.state.defaultTabName} 
            	form={this.state.form} 
            	translatedForm={this.state.translatedForm} 
            	handleClose={this.handleClose} showTOC={this.state.showTOC} 
            	showSlide={this.state.showSlide} 
            	toggleDrawer={this.toggleDrawer} 
            	drawerPaper={classes.drawerPaper} 
            	closeDrawer={this.closeDrawer} />
          </Card>
          <div style={{ textAlign: 'center' }}>
            <Loader loading={this.state.loading} />
          </div>
        </Typography>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar} >
            <Button className={classes.previewButton} color='inherit' 
            	startIcon={<VisibilityIcon />} 
            	onClick={() => { console.log('Preview button') }}>Preview</Button>
            <span className={classes.flexGrow} />
            <Button className={classes.draftButton} variant="outlined" 
            	color='inherit' disableElevation 
            	disabled={!this.state.enableSaveBtn} 
            	onClick={this.saveAsDraft}>Save Draft</Button>
            <Button variant="contained" disableElevation 
            	disabled={this.isDisabled()} 
            	onClick={this.publishForm}>Publish</Button>
          </Toolbar>
        </AppBar>
        <EditFormMetaData open={this.state.showDialog} 
        	onClose={this.handleCancel} />
      </Fragment>
    );
  };
};

function RenderForm(props) {
  return (props.form.children !== undefined) ? 
	(<FormEdit showPopup={props.showPopup} 
		selectedLanguage={props.selectedLanguage} 
		defaultTabName={props.defaultTabName} 
		form={props.form} translatedForm={props.translatedForm} 
		handleClose={props.handleClose} showTOC={props.showTOC} 
		showSlide={props.showSlide} toggleDrawer={props.toggleDrawer} 
		closeDrawer={props.closeDrawer} 
		drawerPaper={props.drawerPaper} />) : (<div></div>);
};

function FormTitle(props) {
  const classes = textFieldStyles();
  const title = (props.title !== undefined) ? props.title : '';
  return (<Titlebar
    title={title} size="small" color="primary" variant="outlined"
    editBtn={<Button variant="outlined" color="primary" 
    onClick={props.updateFormMetaData} disabled={props.isLoading}>Edit</Button>}>
    <Box display="flex" justifyContent="space-between" 
    	bgcolor="background.paper">
      <Box>
        <Grid>
          <TextField label={props.translateIcon} select fullWidth
            InputProps={{ disableUnderline: true }}
            className={classes.textField}
            onChange={props.showLanguages}>
            {props.languages.map((option) => (
              <MenuItem key={option.languageCode} value={option.name} >
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Box>
      <Box>
        <IconButton onClick={props.slideInOut(true)}>
          <TuneSharpIcon />
        </IconButton>
      </Box>
      <Box>
        <IconButton onClick={props.handleEditView(true)}>
          <FormatListNumberedIcon color={props.showToc ? "primary" : "inherit"} />
        </IconButton>
      </Box>
    </Box>
  </Titlebar>);
}

export default withStyles(styles, { withTheme: true })(EditForm);