import { BehaviorSubject, Subject } from 'rxjs';
import FormBuilderService from '../services/form-builder.service';
import { currentPage } from '../views/ExistingForms';

const isMockFlow = false;
const formListSubject = new Subject();
const loadingSubject = new Subject();
const sortingSubject = new Subject();
export const language = new BehaviorSubject();
const formBuilderService = new FormBuilderService();
var languages = [];
var currentSearchQuery = {};
var currentSortParameter = {};
var currentUserSSO = '';

export const formListService = {
    didChangeFormList: () => formListSubject.asObservable(),
    didChangeLoadingState: () => loadingSubject.asObservable(),
    didChangeSortParameter: () => sortingSubject.asObservable(),
    loadAllForms: () => {
        loadAllFormsInternal();
    },
    searchForms: (searchQuery, userSSO) => {
        currentSearchQuery = searchQuery;
        currentUserSSO = userSSO;
        searchFormsInternal(searchQuery);
    },
    clearSearch:() => {
        currentSearchQuery = {}
    },
    sortForms: (sortParameter) => {
        currentSortParameter = sortParameter;
        sortFormsInternal(currentSortParameter);
    },

    formChange: (userSSO) => {
        currentUserSSO = userSSO;
        onLoading(true);
    },
    
    pageChanged:() => {
       pageChangedInternal();
    },
    getFormCategories: (formId) => {
      if (isMockFlow) {
        return null;
      }

      return formBuilderService.getFormCategories(formId);
    },

    getUniversalType:() => {
        return formBuilderService.getUniversalTypes();
    },
  
    getUniversalTypeDetails: (universalType) => {
      return formBuilderService.getUniversalTypeDetails(universalType);
    },

    getLanguageName: (code) => {
		if(languages.length > 0) {
	      const found = languages.find(language => language.id === code || language.languageCode)
	      if (found && found.length > 0) {
	        const foundLang = found[0];
	        return foundLang.name;
	      }
		}
		return '';
    },
    fireApi: () => {
      fireApi();
    }
}

const onLoading = (isLoading) => {
    loadingSubject.next(isLoading);
}

const onSortChanged = (sortParameter) => {
    sortingSubject.next(sortParameter);
}

const loadAllFormsInternal = () => {

    if (isMockFlow) {
        formListSubject.next(mockFormList);
        onLoading(false);
        return;
    }

    loadAllLanguages();
    onLoading(true);
    formBuilderService.getAllForms(currentPage, currentSortParameter, currentUserSSO)
        .then((res) => {
            broadCastFormList([res.data.content, res.data.totalPages, res.data.totalItems, res.data.currentPage]);
        });
}

const broadCastFormList = ([forms, totalPages, totalItems, currentPage]) => {
    forms.forEach((form, i) => {
        const code = form.defaultLanguageCode;
        if (code != null) {
            const displayString = getLanguageName(code);
            form['languageName'] = displayString;
        } else {
            form['languageName'] = '';
        }
    });
    formListSubject.next([forms, totalPages, totalItems, currentPage]);
    onLoading(false);
}

const searchFormsInternal = (searchQuery) => {
    if (isMockFlow) {
        formListSubject.next(mockFormList);
        return;
    }

    onLoading(true);
    formBuilderService.searchByFormName(searchQuery, currentSortParameter, 1, currentUserSSO) // Every fresh search should be made with currentPage as 1
        .then((res) => {
            broadCastFormList([res.data.content, res.data.totalPages, res.data.totalItems, res.data.currentPage]);
        });
}

const sortFormsInternal = (sortParameter) => {
    if (isMockFlow) {
        formListSubject.next(mockFormList);
        return;
    }
    onLoading(true);
    onSortChanged(sortParameter);
    fireApi();
}

const pageChangedInternal = () => {
    if (isMockFlow) {
        formListSubject.next(mockFormList);
        return;
    }
    onLoading(true);
    fireApi();
}

const fireApi = () => {
    // When search query is present
    if (Object.keys(currentSearchQuery).length !== 0) { // If search query is present, trigger search api. For >0 and <3 chars, Sorting will be on the results displayed from the last search
        formBuilderService.searchByFormName(currentSearchQuery, currentSortParameter, currentPage, currentUserSSO)
            .then((res) => {
                broadCastFormList([res.data.content, res.data.totalPages, res.data.totalItems, res.data.currentPage]);
            });
    }
    else {// Sorting list on exiting from Search or when Search isn't used)
        formBuilderService.getAllForms(currentPage, currentSortParameter, currentUserSSO)
            .then((res) => {
                broadCastFormList([res.data.content, res.data.totalPages, res.data.totalItems, res.data.currentPage]);
            });
    }
}

const loadAllLanguages = () => {
  formBuilderService.getDefaultLanguages()
  .then((res) => {
    if (res.data) {
      languages = res.data;
      language.next(res.data);
    }
  });
}

const getLanguageName = (code) => {
  //"id":1,"name":"English","languageCode":"en"
  const found = languages.find(language => language.id === code || language.languageCode === code)
  if (found) {
    return  found.name;
  }

  return '';
}

const mockFormList = [
    {
        "id": 3,
        "formCode": 3,
        "version": 3,
        "title": "Demo Form 1",
        "assetType": null,
        "formStatus": "Draft",
        "description": "This is a demo Form",
        "createdBy": "GE Test user 1",
        "createdDate": 1589973569694,
        "lastUpdatedBy": "GE Test user 2",
        "lastUpdatedDate": 1589973569694,
        "keywords": [],
        "children": [
            {
                "id": 4,
                "order": 2,
                "elementId": "5b9c0711-1d75-4253-8ff8-11f3ef62f48b",
                "componentType": "section",
                "name": "section_id_1",
                "label": "Section Title",
                "buttonIcon": "fa fa-window-maximize",
                "buttonName": "Section",
                "optionsLabel": "Section",
                "enabledActions": [
                    "show",
                    "hide"
                ],
                "enabledConditions": [],
                "children": [
                    {
                        "id": 8,
                        "motherId": 4,
                        "order": 2,
                        "elementId": "a0f6cc33-ea9d-4185-95dd-a2547f6b336d",
                        "componentType": "text",
                        "name": "text_id_1",
                        "label": "Text Field Label",
                        "dataType": "string",
                        "buttonIcon": "fa fa-font",
                        "buttonName": "Text",
                        "optionsLabel": "Text",
                        "enabledActions": [
                            "show",
                            "hide",
                            "answer"
                        ],
                        "enabledConditions": [
                            "eq",
                            "neq",
                            "contains",
                            "answered"
                        ],
                        "children": [],
                        "businessUnit": "ren"
                    },
                    {
                        "id": 5,
                        "motherId": 4,
                        "order": 1,
                        "elementId": "1ca80bd2-7737-4397-9c5e-2f881a7ee4a4",
                        "componentType": "subsection",
                        "name": "subsection_id_1",
                        "label": "Subsection Title",
                        "buttonIcon": "fa fa-window-restore",
                        "buttonName": "Subsection",
                        "optionsLabel": "Subsection",
                        "enabledActions": [
                            "show",
                            "hide"
                        ],
                        "enabledConditions": [],
                        "children": [
                            {
                                "id": 7,
                                "motherId": 5,
                                "order": 2,
                                "elementId": "caafed9f-b632-4f08-96cf-6c1284619f86",
                                "componentType": "email",
                                "name": "email_id_1",
                                "label": "<p>Email Address</p>",
                                "dataType": "string",
                                "buttonIcon": "fa fa-envelope-o",
                                "buttonName": "Email",
                                "optionsLabel": "Email",
                                "enabledActions": [
                                    "show",
                                    "hide",
                                    "answer"
                                ],
                                "enabledConditions": [
                                    "eq",
                                    "neq",
                                    "contains",
                                    "answered"
                                ],
                                "properties": {
                                    "visible": true,
                                    "disabled": false,
                                    "showUpdatedDate": true
                                },
                                "validations": {
                                    "required": true
                                },
                                "children": [],
                                "businessUnit": "ren"
                            },
                            {
                                "id": 6,
                                "motherId": 5,
                                "order": 1,
                                "elementId": "6cd7f04a-ec14-4001-bab8-630883b062b0",
                                "componentType": "select",
                                "name": "select_id_1",
                                "label": "Select Box Label",
                                "dataType": "string",
                                "buttonIcon": "fa fa-caret-square-o-down",
                                "buttonName": "Select Box",
                                "optionsLabel": "Select Box",
                                "enabledActions": [
                                    "show",
                                    "hide",
                                    "answer"
                                ],
                                "enabledConditions": [
                                    "eq",
                                    "neq",
                                    "contains",
                                    "answered"
                                ],
                                "properties": {
                                    "size": 4,
                                    "multiple": false
                                },
                                "options": [
                                    "Select box must have at least 2 options",
                                    "If you only need one option, consider using a checkbox"
                                ],
                                "children": [],
                                "businessUnit": "ren"
                            }
                        ],
                        "businessUnit": "ren"
                    }
                ],
                "businessUnit": "ren"
            },
            {
                "id": 2,
                "order": 1,
                "elementId": "09e58a97-424a-48c3-863f-35e068d3cfe3",
                "componentType": "section",
                "name": "section_id_0",
                "label": "First Section",
                "buttonIcon": "fa fa-window-maximize",
                "buttonName": "Section",
                "optionsLabel": "Section",
                "enabledActions": [
                    "show",
                    "hide"
                ],
                "enabledConditions": [],
                "properties": {},
                "validations": {},
                "children": [
                    {
                        "id": 3,
                        "motherId": 2,
                        "order": 1,
                        "elementId": "03ca8b8f-fa9d-4e75-88ee-f516b3c0fdad",
                        "componentType": "radio",
                        "name": "radio_id_0",
                        "label": "Radio Group Label",
                        "dataType": "string",
                        "buttonIcon": "fa fa-dot-circle-o",
                        "buttonName": "Radio Button",
                        "optionsLabel": "Radio Button",
                        "enabledActions": [
                            {
                                "icon": "fas fa-camera",
                                "name": "capture_image",
                                "label": "Capture Image"
                            },
                            {
                                "icon": "fas fa-comment",
                                "name": "add_comments",
                                "label": "Add Comments"
                            },
                            {
                                "icon": "",
                                "name": "question_logic",
                                "label": "Question Logic",
                                "sub_actions": [
                                    {
                                        "icon": "fa-eye",
                                        "name": "show",
                                        "label": "Show"
                                    },
                                    {
                                        "icon": "fa-eye",
                                        "name": "hide",
                                        "label": "Hide"
                                    }
                                ]
                            }
                        ],
                        "enabledConditions": [],
                        "properties": {
                            "orientation": "vertical"
                        },
                        "options": [
                            "Radio button group must have at least 2 options",
                            "If you only need one option, consider using a checkbox"
                        ],
                        "children": [],
                        "conditional": [
                            {
                                "index": 1,
                                "value": "bcad6006b0-b1ca-4a06-b27d-ab2ea748886f",
                                "actions": [
                                    {
                                        "icon": "fas fa-camera",
                                        "name": "capture_image",
                                        "label": "Capture Image"
                                    },
                                    {
                                        "icon": "fas fa-comment",
                                        "name": "add_comments",
                                        "label": "Add Comments"
                                    },
                                    {
                                        "icon": "fa-eye",
                                        "name": "show",
                                        "label": "Show this",
                                        "targets": [
                                            {
                                                "condition": {
                                                    "name": "eq",
                                                    "label": "Equals",
                                                    "symbol": "="
                                                },
                                                "elementId": "ad6006b0-b1ca-4a06-b27d-ab2ea748886f"
                                            },
                                            {
                                                "condition": {
                                                    "name": "eq",
                                                    "label": "Equals",
                                                    "symbol": "="
                                                },
                                                "elementId": "ad6006b0-b1ca-4a06-b27d-ab2ea748886fsd"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "businessUnit": "ren"
                    }
                ],
                "businessUnit": "ren"
            }
        ],
        "requests": [],
        "responses": null,
        "formCategories": [],
        "enforceOrder": null,
        "copiedFromFormCode": null,
        "copiedFromFormVersion": null,
        "customFormCode": null,
        "isInLine": false,
        "formSupportedLanguages": [],
        "defaultLanguageCode": null,
        "componentType": "form",
        "hashCode": 1360069594
    },
    {
        "id": 2,
        "formCode": 230,
        "version": 1,
        "title": "New Form - 1",
        "assetType": "asset",
        "formStatus": "Draft",
        "description": "form Description",
        "createdBy": "GE Test user 4",
        "createdDate": 1589959036531,
        "lastUpdatedBy": "GE Test user 4",
        "lastUpdatedDate": 1589959036531,
        "keywords": [
            "renewables",
            "wind mill",
            "digital"
        ],
        "children": [],
        "requests": [],
        "responses": null,
        "formCategories": [
            1
        ],
        "enforceOrder": null,
        "copiedFromFormCode": null,
        "copiedFromFormVersion": null,
        "customFormCode": null,
        "isInLine": false,
        "formSupportedLanguages": [],
        "defaultLanguageCode": "en",
        "componentType": "form",
        "hashCode": -448201537
    },
    {
        "id": 1,
        "formCode": 22,
        "version": 10,
        "title": "Demo Form - 3",
        "assetType": null,
        "formStatus": "Draft",
        "description": "This is another Demo Form",
        "createdBy": "GE Test user 2",
        "createdDate": 1589293814110,
        "lastUpdatedBy": "GE Test user 2",
        "lastUpdatedDate": 1589293814110,
        "keywords": [],
        "children": [
            {
                "id": 1,
                "order": 1,
                "elementId": "cf9e0d5d-a2d2-4123-a952-0d2d6086f547",
                "componentType": "section",
                "name": "section_id_0",
                "label": "Test",
                "buttonIcon": "fa fa-window-maximize",
                "buttonName": "Section",
                "optionsLabel": "Section",
                "enabledActions": [
                    "show",
                    "hide"
                ],
                "enabledConditions": [],
                "properties": {},
                "validations": {},
                "children": [],
                "businessUnit": "ren"
            }
        ],
        "requests": [],
        "responses": null,
        "formCategories": [],
        "enforceOrder": true,
        "copiedFromFormCode": null,
        "copiedFromFormVersion": null,
        "customFormCode": "111",
        "isInLine": true,
        "formSupportedLanguages": [],
        "defaultLanguageCode": null,
        "componentType": "form",
        "hashCode": -277703607
    }
];


