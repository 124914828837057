import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

export default function FormDialog(props) {

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: '25%',
    },
}));

const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Lastest version of this form is available, Are you sure want to proceed with older version?
          </DialogContentText>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button onClick={props.handleCancel} variant="outlined" size="small" color="inherit">
            Cancel
          </Button>
          <Button onClick={props.handleConfirm} variant="contained" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}