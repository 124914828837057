import {
  Box,
  Card,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState, useEffect } from "react";
import { EMPTY_STRING, OTHERS, SELECT_TYPE } from "../utility/Constant";
import { FormHelperService } from "../services/form.helper.service";
import UniversalList from "./UniversalList";
import { formListService } from "../services/form-list.service";

export const FIRST_LEVEL = 1;
export const MAX_LEVELS = 4;
export const DEFAULT_UNIVERSAL_SELECTOR_DATA = {
  universalType: SELECT_TYPE,
  uniqueCode: EMPTY_STRING,
  level: FIRST_LEVEL,
  error: false,
};
export const UNIVERSAL_TYPE_MAPPING = {
  "Location Type": "location",
  "Component Type": "component",
  "Component Condition": "condition",
  "Inspection Type": "inspection",
};

const UniversalSelector = ({
  universalSelectorOptions,
  createFormClicked,
  onValidation,
  setUniversalSelectorOptions,
  copyForm,
  updateForm,
}) => {
  const useStyles = makeStyles(() => ({
    numberLabel: {
      fontSize: "14px",
      lineHeight: "28px",
      fontWeight: "bold",
    },
    addIcon: {
      color: "#419BAC",
      fontFamily: "Roboto",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      padding: "0",
    },
    iconButton: {
      padding: "0",
    },
    helperTextPosition: {
      position: "absolute",
      top: "55px"
    }
  }));

  const classes = useStyles();
  const [universalName, setUniversalName] = useState(EMPTY_STRING);
  const [universalSelectorData, setUniversalSelectorData] = useState([
    DEFAULT_UNIVERSAL_SELECTOR_DATA,
  ]);
  const [universalListData, setUniversalListData] = useState({
    name: EMPTY_STRING,
    options: [
      {
        name: EMPTY_STRING,
        universalType: EMPTY_STRING,
        uniqueCode: EMPTY_STRING,
        universalTypeId: EMPTY_STRING,
        level: 1,
        id: FormHelperService.generateElementId(),
        options: [],
      },
    ],
    totalLevels: 0,
  });
  const [universalListOptions, setUniversalListOptions] = useState({});

  useEffect(() => {
    setUniversalListData((prevState) => ({
      ...prevState,
      name: universalName,
      totalLevels: universalSelectorData?.length,
    }));
  }, [universalName, universalSelectorData]);

  useEffect(() => {
    setUniversalSelectorOptions((prevState) => {
      const selectedOptions = universalSelectorData?.map(
        (item) => item?.universalType
      );
      return prevState?.map((option) =>
        selectedOptions?.includes(option?.universalType)
          ? { ...option, hidden: true }
          : { ...option, hidden: false }
      );
    });
  }, [universalSelectorData, setUniversalSelectorOptions]);

  const updateFieldsInUnveralList = (
    options,
    level,
    universalSelectorOption
  ) => {
    return options?.map((item) => {
      if (item?.level === level) {
        return {
          ...item,
          name: "",
          universalType: universalSelectorOption?.universalType,
          uniqueCode: universalSelectorOption?.uniqueCode,
          universalTypeId: universalSelectorOption?.universalTypeId,
        };
      } else {
        return {
          ...item,
          options: updateFieldsInUnveralList(
            item?.options,
            level,
            universalSelectorOption
          ),
        };
      }
    });
  };

  const addItemInUniversalList = (options, level) => {
    return options?.map((item) => {
      if (item?.level === level) {
        if (!item?.options?.length) {
          return {
            ...item,
            options: [
              {
                name: EMPTY_STRING,
                universalType: EMPTY_STRING,
                uniqueCode: EMPTY_STRING,
                universalTypeId: EMPTY_STRING,
                level: level + 1,
                id: FormHelperService.generateElementId(),
                parentId: item?.id,
                options: [],
              },
            ],
          };
        }
      } else {
        return {
          ...item,
          options: addItemInUniversalList(item?.options, level),
        };
      }
      return item;
    });
  };

  const deleteItemsFromUniversalList = (options, level) => {
    if (level === FIRST_LEVEL) {
      return [
        {
          name: EMPTY_STRING,
          universalType: EMPTY_STRING,
          uniqueCode: EMPTY_STRING,
          universalTypeId: EMPTY_STRING,
          level: 1,
          id: FormHelperService.generateElementId(),
          options: [],
        },
      ];
    } else {
      return options?.map((item) => {
        if (item?.level >= level - 1) {
          return { ...item, options: [] };
        } else {
          return {
            ...item,
            options: deleteItemsFromUniversalList(item?.options, level),
          };
        }
      });
    }
  };

  const updateUniversalSelectorData = (levelIdentifier, value) => {
    let universalTypeDetails = [];
    if (value !== OTHERS) {
      const universalType = UNIVERSAL_TYPE_MAPPING[value];
      formListService.getUniversalTypeDetails(universalType).then((res) => {
        const response = res?.data;
        const typeDetails =
          response?.resultSet?.[`${universalType}_type_master_details`];
        typeDetails.forEach((type) => {
          // This logic would change once backend changes are implemented in getUniversalTypeDetails
          if (universalType !== "condition") {
            universalTypeDetails?.push(type[`${universalType}TypeName`]);
          } else {
            universalTypeDetails?.push(type?.componentCondition);
          }
        });
        setUniversalListOptions((prevState) => ({
          ...prevState,
          [`${levelIdentifier}`]: universalTypeDetails,
        }));
      });
    }
    const universalSelectorOption = universalSelectorOptions.find(
      (option) => option.universalType === value
    );
    const uniqueCodeValue = universalSelectorOption?.uniqueCode;
    setUniversalSelectorData((prevState) => {
      return prevState?.map((prev) =>
        prev?.level === levelIdentifier
          ? {
            ...prev,
            universalType: value,
            uniqueCode: uniqueCodeValue,
            error: false,
          }
          : prev
      );
    });
    setUniversalListData((prevState) => ({
      ...prevState,
      options: updateFieldsInUnveralList(
        prevState?.options,
        levelIdentifier,
        universalSelectorOption
      ),
    }));
  };

  const addUniversalSelectorData = (levelIdentifier) => {
    setUniversalSelectorData((prevState) => {
      if (prevState.length < MAX_LEVELS) {
        return [
          ...prevState,
          {
            universalType: SELECT_TYPE,
            uniqueCode: EMPTY_STRING,
            level: levelIdentifier + 1,
            error: false,
          },
        ];
      }
      return prevState;
    });
    setUniversalListData((prevState) => ({
      ...prevState,
      options: addItemInUniversalList(prevState?.options, levelIdentifier),
    }));
  };

  const deleteUniversalSelectorData = (levelIdentifier) => {
    if (levelIdentifier === FIRST_LEVEL) {
      setUniversalSelectorData([DEFAULT_UNIVERSAL_SELECTOR_DATA]);
    } else {
      setUniversalSelectorData((prevState) =>
        prevState?.filter((prev) => prev?.level < levelIdentifier)
      );
    }
    setUniversalListData((prevState) => ({
      ...prevState,
      options: deleteItemsFromUniversalList(
        prevState?.options,
        levelIdentifier
      ),
    }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            id="universalName"
            label="Universal Name"
            name="universalName"
            value={universalName}
            onChange={(e) => setUniversalName(e.target.value)}
            required
            variant="outlined"
            style={{ marginTop: "12px" }}
            error={createFormClicked && !universalName?.length}
            helperText={
              createFormClicked && !universalName?.length
                ? "Please enter Universal Name"
                : EMPTY_STRING
            }
            FormHelperTextProps={{
              className: classes.helperTextPosition
            }}
          />
        </Grid>
      </Grid>
      <div style={{ paddingTop: "24px" }}>
        <Card>
          <Box p={4}>
            <Grid container spacing={3} pb={4}>
              {universalSelectorData.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={updateForm || copyForm ? 12 : 6}
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ marginTop: index >= 2 ? "12px" : "0" }}
                  >
                    <Grid item>
                      <Typography
                        variant="h6"
                        align="center"
                        className={classes.numberLabel}
                      >
                        {item?.level}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ marginRight: "13px" }}>
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        value={item.universalType}
                        onChange={(event) =>
                          updateUniversalSelectorData(
                            item?.level,
                            event.target.value
                          )
                        }
                        error={
                          (createFormClicked &&
                            item.universalType === SELECT_TYPE) ||
                          item?.error
                        }
                        helperText={
                          createFormClicked &&
                            item.universalType === SELECT_TYPE
                            ? "Please select a type"
                            : EMPTY_STRING
                        }
                        FormHelperTextProps={{
                          className: classes.helperTextPosition
                        }}
                      >
                        <MenuItem
                          value={SELECT_TYPE}
                          disabled
                          style={{ color: "#999" }}
                        >
                          {SELECT_TYPE}
                        </MenuItem>
                        {universalSelectorOptions.map(
                          ({ uniqueCode, universalType, hidden = false }) => (
                            <MenuItem
                              style={{ display: hidden ? "none" : "block" }}
                              key={uniqueCode}
                              value={universalType}
                            >
                              {universalType}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Unique Code"
                        value={item.uniqueCode}
                        onChange={(event) => {
                          if (item.universalType === OTHERS) {
                            const inputValue = event.target.value;
                            setUniversalSelectorData((prevState) =>
                              prevState?.map((prev) =>
                                prev?.level === item?.level
                                  ? { ...prev, uniqueCode: inputValue }
                                  : prev
                              )
                            );
                          }
                        }}
                        InputProps={{
                          readOnly: item.universalType !== OTHERS,
                        }}
                      ></TextField>
                    </Grid>
                    {universalSelectorData.length > 1 && (
                      <Grid item>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() =>
                            deleteUniversalSelectorData(item?.level)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                    {item?.level === universalSelectorData.length &&
                      universalSelectorData.length < MAX_LEVELS &&
                      item.universalType !== SELECT_TYPE && (
                        <Grid>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() =>
                              addUniversalSelectorData(item?.level)
                            }
                          >
                            <AddIcon className={classes.addIcon} />
                          </IconButton>
                        </Grid>
                      )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
          <UniversalList
            universalListData={universalListData}
            setUniversalListData={setUniversalListData}
            universalSelectorData={universalSelectorData}
            universalListOptions={universalListOptions}
            createFormClicked={createFormClicked}
            onValidation={onValidation}
          />
        </Card>
      </div>
    </>
  );
};

export default UniversalSelector;
