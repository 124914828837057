import { BehaviorSubject } from "rxjs";

var currentUser = {};
const currentUserState = new BehaviorSubject(currentUser);

class SessionManager {
  static _instance = null;

  constructor() {
    this.user = {};
  }

  static sharedInstance() {
    if (SessionManager._instance == null) {
      SessionManager._instance = new SessionManager();
    }
    return this._instance;
  }

  updateCurrentUser = (currentUser) => {
    this.user = currentUser;
    currentUserState.next(currentUser);
  };
}

export { SessionManager, currentUserState };
